<template>
    <el-dropdown @command="handleCommand" v-if="user != ''">

        <span class="el-dropdown-link">
            <i class="el-icon-user"></i>
            {{ username }}
            <i class="el-icon-caret-bottom"></i>
        </span>
        <el-dropdown-menu slot="dropdown" size="medium">
            <el-dropdown-item command="userinfo">个人信息</el-dropdown-item>

            <el-dropdown-item command="logout">退出</el-dropdown-item>

        </el-dropdown-menu>
    </el-dropdown>
</template>
<script>
import { mapMutations } from 'vuex';
export default {

    computed: {
        username() {
            if (this.$store.state.username === null) {
                return ""
            }
            return this.$store.state.username;
        },
        user() {
            if (this.$store.state.userid === null) {
                return ""
            }
            return this.$store.state.userid;
        }
    },
    methods: {
        ...mapMutations(['Logout']),
        handleCommand(command) {
            if (command === "logout") {
                this.Logout()
                if (this.$route.fullPath != "/login") {
                    this.$router.push('/login?redirect=' + this.$route.fullPath)
                }
            } else if (command === 'userinfo') {
                this.$router.push({ path: '/userinfo', query: { username: this.user } })
            }
        }
    }
}
</script>

<style scoped>
.el-dropdown {
    margin: 10px;
    padding: 10px;
    font-size: medium;
    float: right;
    background-color: #F5F7FB;
    border-radius: 8px;
}

.el-dropdown-link {
    cursor: pointer;
    margin: 5px;
}

.el-dropdown-menu__item {
    text-align: center;
}
</style>