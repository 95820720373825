<template>
    <div>
        <BreadcrumbItem :breaditems="items"></BreadcrumbItem>

        <el-row>
            <div class="process_type">
                <el-form :inline="true" :model="formInline" class="demo-form-inline" disabled>
                    <el-form-item label="输入搜索">
                        <el-input v-model="formInline.name" placeholder="任务名称" size="small" ></el-input>
                    </el-form-item>

                    <el-form-item label="任务类型">
                        <el-select v-model="formInline.task_type" placeholder="请选择" size="small">
                            <el-option v-for="item in task_classes" :key="item.code" :label="item.name"
                                :value="item.code" >
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
        </el-row>

        <el-row class="buttonArr">
            <!-- <el-button type="danger" size="small" icon="el-icon-delete"></el-button> -->
            <el-button type="primary" size="small" @click="dialog = true">新建</el-button>
            <el-button type="success" plain size="small" icon="el-icon-refresh" @click="init">刷新</el-button>

            <CreateTask :dialog="dialog" @disableDialog="disableDialog"></CreateTask>
        </el-row>

        <el-table border ref="multipleTable" @selection-change="handleSelectionChange" :data="tableData" height="500"
            v-loading="tableLoading">
            <!-- <el-table-column type="selection" width="55">
            </el-table-column> -->

            <el-table-column prop="name" label="任务名称">
                <template slot-scope="scope">
                    <el-button type="text" @click="gotoresult(scope.row.code, scope.row.name,)"
                        v-if="scope.row.status !== 0">{{
                                scope.row.name
                        }}</el-button>
                    <span v-else>{{ scope.row.name }}</span>
                </template>
            </el-table-column>
            <!-- <el-table-column label="图片">
                <template slot-scope="scope">
                    <img :src="scope.row.pic" width="40" height="40" />
                </template>
            </el-table-column> -->
            <el-table-column prop="task_type.name" label="任务类型">
            </el-table-column>
            <!-- <el-table-column prop="amount" label="数量">
            </el-table-column> -->
            <el-table-column prop="task_type.crop.name" label="物体类型">
            </el-table-column>
            <el-table-column prop="task_type.device.name" label="设备型号">
            </el-table-column>
            <el-table-column label="任务状态" prop="status">
                <template slot-scope="scope">
                    <div v-if="scope.row.status === 0">
                        未运行
                    </div>
                    <div v-if="scope.row.status === 1">
                        运行中
                    </div>
                    <div v-if="scope.row.status === 2">
                        完成
                    </div>
                    <div v-if="scope.row.status === 3">
                        失败
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="开始时间">
                <template slot-scope="scope">
                    <div v-if="scope.row.status > 0">
                        {{ scope.row.start_at }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="结束时间">
                <template slot-scope="scope">
                    <div v-if="scope.row.status > 1">
                        {{ scope.row.end_at }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button size="normal" type="text" @click="deletetask(scope.row.code)"
                        v-if="scope.row.status !== 1">删除</el-button>             
                    <el-button type="success" size="mini" v-if="scope.row.status === 0"
                        @click="runtask(scope.row.code)">
                        运行</el-button>
                    <el-button type="warning" size="normal" v-if="scope.row.status === 1"
                        @click="terminatetask(scope.row.code)">
                        终止</el-button>
                    <el-button type="success" size="mini" v-if="scope.row.status === 2"
                        @click="runtask(scope.row.code)">
                        再次运行</el-button>      
                    <el-button type="success" size="mini" v-if="scope.row.status === 3"
                        @click="runtask(scope.row.code)">
                        再次运行</el-button>                                              
                </template>
            </el-table-column>
        </el-table>
        <el-pagination @current-change="pageChange" :current-page="currentPage" :page-size="pageSize"
            layout="total, prev, pager, next" :total="currentTotal">
        </el-pagination>

    </div>

</template>
<style scoped>
.el-table {
    padding: 2px;
    /* min-height: 700px; */
    /* margin: 2px; */
}

.buttonArr {
    padding: 5px;
    margin-block-end: 11px;
}

.process_type {
    float: left;
}

.el-pagination {
    margin-block-start: 12px;
}

.el-form-item {
    margin-bottom: 1px;
}
</style>
<script>
import { GetTasks, DelTask, GetCrops, RunTask,TerminateTask, GetTaskClass, GetDevices } from '../../axios/axios';
import BreadcrumbItem from '../common/BreadcrumbItem.vue';
import CreateTask from './CreateTask.vue'
import { deleteAllObjects } from '../../minio/minio'
export default {
    components: { BreadcrumbItem, CreateTask },
    data() {
        return {
            items: ["数据任务", "任务"],
            dialog: false,
            tableData: [
            ],
            tableLoading: false,
            crops: [],
            task_classes: [],
            devices: [],
            formInline: {
                name: '',
                task_type: { device: { name: "" } },
                region: ''
            },
            currentPage: 1,
            currentTotal: 0,
            pageSize: 10
        }
    },
    mounted() {
        this.init()
    },
    computed: {
        user() {
            if (this.$store.state.userid === null) {
                return ""
            }
            return this.$store.state.userid;
        },
    },
    methods: {
        pageChange(val) {
            this.tableLoading = true

            GetTasks(val, this.pageSize).then(res => {
                this.tableData = res.data.data
                this.pages = res.data.page_info
                this.tableLoading = false
                this.currentPage = val

            })
            this.tableLoading = false
        },
        init() {
            this.tableLoading = true
            GetTasks(this.currentPage, this.pageSize).then(res => {
                this.tableData = res.data.data
                this.pages = res.data.page_info
                this.tableLoading = false
                this.currentTotal = this.pages.total
            }).catch(() => {
                this.tableLoading = false
            })
            GetCrops().then(res => {
                this.crops = res.data.data
            })
            GetTaskClass().then(res => {
                this.task_classes = res.data.data
            })
            GetDevices().then(res => {
                this.devices = res.data.data
            })
        },
        onSubmit() {
            console.log('submit!');
        },
        disableDialog() {
            this.dialog = false
            this.init()
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        gotoresult(id, name) {
            this.$router.push({ path: "/result/taskresult", query: { id: id, name: name } })
        },
        deletetask(task_id) {
            deleteAllObjects(this.user, "tasks/" + task_id)

            DelTask(task_id).then(
                res => {
                    console.log(res)
                    this.$message.success('删除成功')
                    this.init()
                }
            ).catch(err => {
                console.log(err)
                this.$message.error('删除失败')
            })
        },
        runtask(task_id) {
            this.tableLoading = true

            RunTask(task_id).then(
                res => {
                    console.log(res)
                    this.$message.success('启动成功')
                    this.init()
                    this.tableLoading = false

                }
            ).catch(err => {
                console.log(err)
                this.$message.error('启动失败')
                this.tableLoading = false
            })
        },
        terminatetask(task_id) {
            this.tableLoading = true

            TerminateTask(task_id).then(
                res => {
                    console.log(res)
                    this.$message.success('已终止')
                    this.init()
                    this.tableLoading = false

                }
            ).catch(err => {
                console.log(err)
                this.$message.error('终止失败')
                this.tableLoading = false
            })
        }
    }
}
</script>